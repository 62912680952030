<template>
  <div class="nw-nav">
    <nav class="navbar navbar-expand-md navbar-light justify-content-between">
      <a class="navbar-brand d-none d-md-block" href="#"><img class="nw-logo" src="@/assets/nightware-logo.png" alt="NightWare"/></a>
      <a href="#" class="navbar-brand d-md-none"><img class="nw-logo" src="@/assets/nightware-logo.png" alt="NightWare"/></a>
      <a href="#" class="navbar-brand"></a>
    </nav>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data () {
      return { logo: process.env.VUE_APP_LOGO }
  },
  methods: {
    toggleLang : function(_lang)
      {
        this.$ml.change(_lang)
        //NetClient.lang = _lang
      }
  }

}
</script>
<style scoped>
.nw-nav {
  background-color: #FFFFFF;
}
</style>
