<template>
  <div class="nw-landing min-vh-100">
    <top-navbar></top-navbar>
    <router-view></router-view>
  </div>
</template>

<script>
  import TopNavbar from './TopNavbar.vue'

  export default {
    components: {
      TopNavbar
    }
  }

</script>
<style scoped>
.nw-landing {
  background-image: url('../assets/background-home.png');
  background-repeat: no-repeat;
	background-size: cover;

}
</style>
